import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { useHistory, Link } from 'react-router-dom';
import { uniqBy, debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Button, Input, Table, Tag, Pagination, Select, Spin } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

import UploadCSVQuestionsModal from '../../assessments/UploadCSVQuestionsModal';
import * as API from '../../../API';
import config from '../../../config';
import { useQuery, gql } from '@apollo/client';
import { CourseTypeTitles } from '../../../constants';
import { useGetDisplayableCourseIdsByCourseType } from '../../courseTypes/hooks/useGetDisplayableCourseIdsByCourseType';

import './QuestionsList.css';

export default function QuestionsList({
  onSelectionHandler,
  disabledQuestionIds = []
}) {
  const [questions, setQuestions] = useState([]);

  const [totalQuestionCount, setTotalQuestionCount] = useState([]);
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);

  const [allQbankCoursesList, setAllQbankCoursesList] = useState([]);
  const [allTagsList, setAllTagsList] = useState([]);

  const [coursesFilter, setCoursesFilter] = useState(null);
  const [tagFilter, setTagsFilter] = useState(null);
  const [searchQuestionFilter, setSearchQuestionFilter] = useState(null);

  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const questionCourseTypeTitlesToDisplay = [
    CourseTypeTitles.ASSESSMENT,
    CourseTypeTitles.BOOTCAMP_ASSESSMENT,
    CourseTypeTitles.PRACTICE_EXAM,
    CourseTypeTitles.QBANK
  ];
  const {
    courseIds: questionCourseIds,
    loading: loadingQuestionCourseIds
  } = useGetDisplayableCourseIdsByCourseType(questionCourseTypeTitlesToDisplay);

  const QUESTION_UNIVERSAL_TAG_QUERY = gql`
    query questionUniversalTag($courseIdsForFilter: [UUID!]) {
      questionUniversalTagBindingsList {
        tag {
          id
          title
        }
      }

      coursesList(filter: { id: { in: $courseIdsForFilter } }) {
        id
        title
      }
    }
  `;

  const { data } = useQuery(QUESTION_UNIVERSAL_TAG_QUERY, {
    variables: { courseIdsForFilter: questionCourseIds },
    skip: !questionCourseIds
  });

  useEffect(() => {
    function populateFilters(data) {
      const { questionUniversalTagBindingsList, coursesList } = data;

      const uniqueFlattenedSortedTags = uniqBy(
        questionUniversalTagBindingsList
          .flatMap(tag => ({ id: tag.tag.id, title: tag.tag.title }))
          .sort((a, b) => a.title.localeCompare(b.title)),
        'id'
      );
      const coursesForSort = [...coursesList];
      const sortedCourses = coursesForSort.sort((a, b) =>
        a.title.localeCompare(b.title)
      );

      setAllTagsList(uniqueFlattenedSortedTags);
      setAllQbankCoursesList(sortedCourses);
    }

    if (data && !loadingQuestionCourseIds) {
      populateFilters(data);
    }
  }, [data, loadingQuestionCourseIds]);

  useEffect(() => {
    async function fetchQuestionsData() {
      setLoading(true);
      const {
        adminQuestions: { totalCount, adminQuestions: allQuestions }
      } = await API.graphileGraphql({
        query: `{
              adminQuestions (
                paginationOptions: {
                  limit:${limit}
                  offset:${offset}
                }
                tagIds:${JSON.stringify(tagFilter)}
                courseIds:${JSON.stringify(coursesFilter)}
                userInputQuestionSearch:${JSON.stringify(searchQuestionFilter)}
              ) {
              totalCount
              adminQuestions {
                id
                body
                course {
                    id
                    title
                    shortname
                    courseType {
                        id
                        shortname
                    }
                }
                tags {
                    id
                    title
                    isPrimary
                    image {
                      id
                      url
                    }
                }
            }
          }
        }`
      });
      setQuestions(allQuestions);
      setTotalQuestionCount(totalCount);
      setLoading(false);
    }
    fetchQuestionsData();
  }, [limit, offset, coursesFilter, tagFilter, searchQuestionFilter]);

  const handleCoursesFilterChange = async incomingCourseIds => {
    setCoursesFilter(incomingCourseIds);
  };

  const handleTagsFilterChange = async incomingTagIds => {
    setTagsFilter(incomingTagIds);
  };

  const debouncedSearchFilterChanged = debounce(async incomingSearchValue => {
    setSearchQuestionFilter(incomingSearchValue);
  }, 500);

  const handleSearchFilterChange = useCallback(debouncedSearchFilterChanged, [
    debouncedSearchFilterChanged
  ]);

  const addNewQuestion = async () => {
    const id = uuidv4();
    const question = await API.question.create({
      attributes: {
        id,
        body: '<p>New Question Stem</p>',
        options: [
          { body: '', explanation: '', correctOption: true },
          { body: '', explanation: '', correctOption: false },
          { body: '', explanation: '', correctOption: false },
          { body: '', explanation: '', correctOption: false }
        ],
        inReview: true,
        group: id,
        createdAt: moment(),
        updatedAt: moment(),
        version: 1,
        correctAnswerIndex: 0,
        isMostRecentVersion: true
      },
      relationships: {
        course: {
          data: {
            type: 'course',
            id: config.defaults.defaultQuestionCourseId
          }
        },
        product: {
          data: {
            type: 'product',
            id: config.defaults.defaultQuestionProductId
          }
        }
      }
    });
    history.push({
      pathname: `/content/questions/${question.data.id}`,
      state: { isNew: true }
    });
  };

  const tableColumns = [
    {
      title: 'Id',
      key: 'id',
      className: 'questions-list__id-column',
      render: (text, record) => (
        <div
          className={classNames('questions-list__text', {
            'questions-list__text--disabled': record.inReview
          })}
          dangerouslySetInnerHTML={{ __html: record.id }}
        />
      )
    },
    {
      title: 'Stem',
      key: 'stem',
      className: 'questions-list__stem-column',
      render: (text, record) => (
        <div
          className={classNames('questions-list__text', {
            'questions-list__text--disabled': record.inReview
          })}
          dangerouslySetInnerHTML={{ __html: record.body }}
        />
      )
    },
    {
      title: 'Course',
      key: 'course',
      className: 'questions-list__course-column',
      render: (text, record) => (
        <div
          className={classNames('questions-list__text', {
            'questions-list__text--disabled': record.inReview
          })}
        >
          {record.course.shortname}
        </div>
      )
    },
    {
      title: 'Tags',
      key: 'tags',
      className: 'questions-list__tags-column',
      render: (text, record) => {
        const tags = record.tags.sort((a, b) => {
          if (a.isPrimary) {
            return -1;
          }
          if (b.isPrimary) {
            return 1;
          }
          return 0;
        });
        return tags.map(tag => (
          <Tag
            className="question__tag"
            key={tag.id}
            color={tag.isPrimary ? 'magenta' : 'blue'}
          >
            {tag.title}
          </Tag>
        ));
      }
    },
    {
      title: '',
      key: 'select',
      className: 'questions-list__select-column',
      render: (text, record) => (
        <Link
          to={{
            pathname: `/content/questions/${record.id}`,
            state: { isNew: false }
          }}
          className="questions-list__question-edit-button"
        >
          <EditOutlined />
        </Link>
      )
    }
  ];

  let rowSelection = null;
  if (onSelectionHandler) {
    rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        onSelectionHandler(selectedRows);
      },
      getCheckboxProps: record => ({
        disabled: disabledQuestionIds.includes(record.id)
      })
    };
  }

  return (
    <div>
      <div className="questions-menu-bar">
        <Input
          className="questions-menu-bar__left--body"
          allowClear={true}
          placeholder="Search query (search by QuestionId or question body)"
          onChange={e => handleSearchFilterChange(e.target.value)}
        />
        <div className="questions-menu-bar__right">
          <Select
            className="questions-menu-bar__right--course"
            placeholder="Select Courses To Filter By"
            defaultValue={allQbankCoursesList}
            mode="multiple"
            onChange={value => {
              handleCoursesFilterChange(value);
            }}
          >
            {allQbankCoursesList &&
              allQbankCoursesList.map(course => (
                <Select.Option key={course.title} value={course.id}>
                  {course.title}
                </Select.Option>
              ))}
          </Select>
          <Select
            className="questions-menu-bar__right--tag"
            placeholder="Select Tags To Filter By"
            defaultValue={allTagsList}
            mode="multiple"
            onChange={value => {
              handleTagsFilterChange(value);
            }}
          >
            {allTagsList &&
              allTagsList.map(tag => (
                <Select.Option key={tag.id} value={tag.id}>
                  {tag.title}
                </Select.Option>
              ))}
          </Select>
        </div>
      </div>

      <div className="questions-header">
        <Button
          className="questions-header__new"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => addNewQuestion()}
        >
          Add New Question
        </Button>
        <UploadCSVQuestionsModal />
      </div>
      <hr />
      {loading && <Spin />}
      {!loading && questions && (
        <>
          <Table
            style={{ backgroundColor: '#fff' }}
            rowKey="id"
            pagination={false}
            dataSource={questions}
            columns={tableColumns}
            onRow={record => ({
              onClick: () => {
                window.location = '/content/questions/' + record.id;
              }
            })}
            rowSelection={rowSelection}
          />
          <Pagination
            current={offset / limit + 1}
            onChange={(pageNumber, pageSize) => {
              setLimit(pageSize);
              setOffset(pageSize * (pageNumber - 1));
            }}
            total={totalQuestionCount}
            showTotal={total => `${total} total questions`}
            pageSize={limit}
            showSizeChanger={true}
          />
        </>
      )}
    </div>
  );
}
