import React, { useCallback, useMemo, useState } from 'react';
import * as API from '../../API';
import _ from 'underscore';
import uniqBy from 'lodash/uniqBy';
import { notify } from 'react-notify-toast';
import { Form, Input, Select, Tag } from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  WarningOutlined,
  MailOutlined,
  WarningTwoTone,
  MinusCircleTwoTone,
  DownloadOutlined
} from '@ant-design/icons';
import {
  Popconfirm,
  Button,
  Table,
  Row,
  Col,
  Checkbox,
  Card,
  Space,
  Tooltip,
  DatePicker
} from 'antd';
import UploadCSVModal from './UploadCSVModal';
import UserAddModal from '../account/UserAddModal';
import CustomEmailModal from '../../components/CustomEmailModal/CustomEmailModal';
import { ResourceSelectorModal } from '../../components/ResourceSelectorModal';
import { Label } from 'reactstrap';
import orderBy from 'lodash/orderBy';
import makeListString from '../../utils/makeListString';
import { createSubscription } from '../../utils/subscription';
import classNames from 'classnames';
import {
  subMinutes,
  subDays,
  format,
  parseISO,
  isAfter,
  isBefore
} from 'date-fns';

import './Group.css';

export default function GroupMembers({
  memberType,
  group,
  accounts,
  subscriptions,
  history,
  loadingAccounts,
  loadAccounts,
  loadSubscriptions
}) {
  const [memberFilterForm] = Form.useForm();
  const [memberFilters, setMemberFilters] = useState([]);
  const allUserSubscriptions = useMemo(
    () =>
      subscriptions
        ?.flatMap(groupCourseSub => groupCourseSub.subscriptionsList)
        .filter(sub => !!sub.account),
    [subscriptions]
  );

  const [showAddMember, setShowAddMember] = useState(false);
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [showCreateMember, setShowCreateMember] = useState(false);
  const [showCreateAdmin, setShowCreateAdmin] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [currentSelectedEmailUsers, setCurrentSelectedEmailUsers] = useState(
    []
  );
  const [memberSelectedRows, setMemberSelectedRows] = useState([]);
  const [adminSelectedRows, setAdminSelectedRows] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [courseFilter, setCourseFilter] = useState(null);
  const [subscriptionDateFilter, setSubscriptionDateFilter] = useState(null);
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    pageSize: 20
  });

  const newMemberIndicatorTime = subMinutes(new Date(), 30);
  const isAdminTable = memberType === 'Admin';
  const member = isAdminTable
    ? {
        titleName: 'Group Admin(s)',
        addMember: setShowAddAdmin,
        createMember: setShowCreateAdmin,
        groupMemberType: 'isAdmin',
        orderBy: 'account.email',
        actionsTitle: '',
        selectedRows: adminSelectedRows
      }
    : {
        titleName: 'Member Name',
        addMember: setShowAddMember,
        createMember: setShowCreateAdmin,
        groupMemberType: 'isMember',
        orderBy: 'account.firstName',
        actionsTitle: 'Actions',
        selectedRows: memberSelectedRows
      };

  const isGroupInList = (groupBindings, groupId) =>
    !!groupBindings.filter(binding => binding.program.id === groupId).length;

  const AccountBindingWarning = ({ groupBindings, groupId }) => {
    const activeGroupBindings = groupBindings.filter(
      binding => !binding.deletedAt
    );
    const isInThisGroup = isGroupInList(activeGroupBindings, groupId);
    const tooltipTitle = isInThisGroup
      ? 'Member is already in this group'
      : `Member is already in another group : ${makeListString(
          activeGroupBindings.map(binding => binding.program.name)
        )}`;

    return activeGroupBindings?.length ? (
      <Tooltip title={tooltipTitle}>
        {isInThisGroup ? (
          <MinusCircleTwoTone color="#ccc" />
        ) : (
          <WarningTwoTone color="pink" />
        )}
      </Tooltip>
    ) : null;
  };

  const getCourseSubscriptions = subscriptions => {
    return (
      subscriptions?.length > 0 &&
      subscriptions.reduce((courses, subscription) => {
        if (courses[subscription.course.id] !== undefined) {
          courses[subscription.course.id].subscriptions.push(subscription);
          courses[subscription.course.id].subscriptions = courses[
            subscription.course.id
          ].subscriptions.flat();
        } else {
          courses[subscription.course.id] = {
            subscriptions: [subscription],
            title: subscription.course.title,
            id: subscription.course.id
          };
        }
        return courses;
      }, {})
    );
  };

  const getCourseSubscriptionList = (courseSubscriptions, columns) => {
    const courseSubscriptionsList = [];
    const now = new Date();

    const generateSubscriptionText = (startDate, endDate) => {
      return (
        (startDate
          ? format(parseISO(startDate), "MM'/'dd'/'yy")
          : 'no start date') +
        ' - ' +
        (endDate ? format(parseISO(endDate), "MM'/'dd'/'yy") : 'no end date')
      );
    };

    /* eslint-disable no-unused-vars */
    for (const [key, value] of Object.entries(courseSubscriptions)) {
      courseSubscriptionsList.push(value);
    }
    courseSubscriptionsList.sort((a, b) => a.title - b.title);

    courseSubscriptionsList.forEach(courseSubscription => {
      const options = [];
      courseSubscription.subscriptions.forEach(subscriptionBinding =>
        subscriptionBinding.subscriptionsList?.forEach(sub => {
          const subText = generateSubscriptionText(sub.startedAt, sub.endedAt);
          options.push({
            text: subText,
            value:
              (sub.startedAt
                ? format(parseISO(sub.startedAt), "MM'/'dd'/'yy")
                : null) +
              (sub.endedAt
                ? format(parseISO(sub.endedAt), "MM'/'dd'/'yy")
                : null)
          });
        })
      );
      // remove duplicates
      const filterOptions = options.filter(
        (element, index, array) =>
          array?.findIndex(e => e.value === element.value) === index
      );

      const expiredfilterOptions = [
        {
          text: 'Expired',
          value: 'expired'
        },
        {
          text: 'Expires Within 30 days',
          value: 'expiring-soon'
        },
        ...filterOptions
      ];

      const selectOptions = [];
      courseSubscription.subscriptions.forEach(subscriptionBinding => {
        const availableSubs = subscriptionBinding.subscriptionsList.filter(
          subscription =>
            !subscription.account &&
            (subscription.endedAt === null ||
              isAfter(parseISO(subscription.endedAt), new Date())) &&
            isBefore(parseISO(subscription.startedAt), new Date())
        );

        availableSubs.forEach(sub => {
          const subText = generateSubscriptionText(sub.startedAt, sub.endedAt);
          const existingSubscription = selectOptions?.find(
            existingOption => existingOption.text === subText
          );
          if (!existingSubscription) {
            selectOptions.push({
              text: subText,
              value: subText,
              subscriptions: [sub.id]
            });
          }
        });
      });

      if (!courseFilter || courseFilter === courseSubscription.title) {
        columns.push({
          title: courseSubscription.title,
          key: courseSubscription.title,
          filters: expiredfilterOptions,
          filteredValue: subscriptionDateFilter
            ? subscriptionDateFilter[courseSubscription.title]
            : [],
          onFilter: (value, record) => {
            const currentSub = courseSubscription.subscriptions.find(
              subscriptionBinding =>
                subscriptionBinding.subscriptionsList.find(
                  sub => sub?.account?.id === record.account?.id
                )
            );

            if (value === 'expired') {
              return currentSub?.endDate
                ? isBefore(parseISO(currentSub.endDate), now)
                : null;
            }
            if (value === 'expiring-soon') {
              return currentSub?.endDate
                ? isAfter(parseISO(currentSub.endDate), now) &&
                    isBefore(subDays(parseISO(currentSub.endDate), 31), now)
                : null;
            }

            return (
              value ===
              (currentSub?.startDate
                ? format(parseISO(currentSub.startDate), "MM'/'dd'/'yy")
                : null) +
                (currentSub?.endDate
                  ? format(parseISO(currentSub.endDate), "MM'/'dd'/'yy")
                  : null)
            );
          },
          render: (text, record) => {
            const currentSub = courseSubscription.subscriptions.find(
              subscriptionBinding =>
                subscriptionBinding.subscriptionsList.find(
                  sub => sub?.account?.id === record.account?.id
                )
            );

            const currentSubDateRange =
              (currentSub?.startDate
                ? format(parseISO(currentSub.startDate), "MM'/'dd'/'yy")
                : null) +
              ' - ' +
              (currentSub?.endDate
                ? format(parseISO(currentSub.endDate), "MM'/'dd'/'yy")
                : null);

            const activeSub = isAfter(parseISO(currentSub?.endDate), now);
            const expiredSub = !activeSub;
            const emptySub = !currentSub;
            let canAddMembers = false;

            courseSubscription.subscriptions.forEach(subscriptionBinding => {
              const availableSubscription = subscriptionBinding.subscriptionsList?.find(
                sub => {
                  const now = new Date();
                  return (
                    !sub.account &&
                    (sub.endedAt === null ||
                      isAfter(parseISO(sub.endedAt), now))
                  );
                }
              );
              if (availableSubscription) {
                canAddMembers = true;
              }
            });

            if (courseSubscription.subscriptions.length > 0) {
              return (
                <div className="member-sub__options">
                  <Select
                    placeholder={emptySub && <p>+ Add Sub</p>}
                    dropdownMatchSelectWidth={true}
                    className={classNames(
                      activeSub && 'member-sub__options-active-sub',
                      expiredSub && 'member-sub__options-expired-sub'
                    )}
                    value={currentSub ? currentSubDateRange : null}
                    onSelect={async value => {
                      const subscriptionValue = selectOptions.find(
                        sub => sub.value === value
                      );
                      await updateMemberSubscriptionAssignment({
                        memberId: record.id,
                        courseId: courseSubscription.id,
                        previousBoolean: !!courseSubscription.subscriptions.find(
                          sub =>
                            sub.account && sub.account.id === record.account.id
                        ),
                        accountId: record.account.id,
                        subscriptions: courseSubscription.subscriptions,
                        newSubscriptionId: subscriptionValue.subscriptions[0]
                      });
                      loadAccounts();
                    }}
                    allowClear={true}
                    onClear={async () => {
                      await updateMemberSubscriptionAssignment({
                        memberId: record.id,
                        courseId: courseSubscription.id,
                        previousBoolean: !!courseSubscription.subscriptions.find(
                          sub =>
                            sub.account && sub.account.id === record.account.id
                        ),
                        accountId: record.account.id,
                        subscriptions: courseSubscription.subscriptions
                      });
                      loadAccounts();
                    }}
                  >
                    {selectOptions.map((sub, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={sub.value}
                          disabled={
                            sub.account?.id ? true : false || !canAddMembers
                          }
                        >
                          {sub.text}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              );
            } else {
              return <p>No subs available</p>;
            }
          }
        });
      }
    });
    return columns;
  };

  const updateMemberSubscriptionAssignment = async ({
    memberId,
    courseId,
    previousBoolean,
    accountId,
    subscriptions,
    newSubscriptionId
  }) => {
    const bindingToUpdate = await API.programMemberCourseBinding.where({
      filter: {
        programAccountBinding: memberId,
        course: courseId
      },
      options: {}
    });
    if (previousBoolean) {
      try {
        const subscriptionToRemoveMember = subscriptions.find(
          subscription =>
            subscription.account && subscription.account.id === accountId
        );
        await API.subscription.update({
          id: subscriptionToRemoveMember.id,
          relationships: {
            account: {
              data: null
            }
          }
        });
        await API.programMemberCourseBinding.delete({
          id: bindingToUpdate.data[0]?.id
        });
      } catch (error) {
        if (error.data) {
          notify.show(error.data.errors[0].detail[0].message, 'error');
        } else {
          throw error;
        }
      }
    } else {
      try {
        if (newSubscriptionId) {
          await API.subscription.update({
            id: newSubscriptionId,
            relationships: {
              account: {
                data: {
                  type: 'account',
                  id: accountId
                }
              }
            }
          });
          if (bindingToUpdate.data[0]?.id) {
            await API.programMemberCourseBinding.update({
              id: bindingToUpdate.data[0].id,
              attributes: {
                deletedAt: null
              }
            });
          } else {
            await API.programMemberCourseBinding.create({
              attributes: {
                createdAt: new Date(),
                updatedAt: new Date()
              },
              relationships: {
                programAccountBinding: {
                  data: { type: 'programAccountBinding', id: memberId }
                },
                course: { data: { type: 'course', id: courseId } }
              }
            });
          }
        } else {
          notify.show('No active subscriptions available to add', 'error');
        }
      } catch (error) {
        if (error.data) {
          notify.show(error.data.errors[0].detail[0].message, 'error');
        } else {
          throw error;
        }
      }
    }
    // Load only subscriptions
    loadSubscriptions();
  };

  const getMemberTableColumns = subscriptions => {
    const columns = [
      {
        title: member.titleName,
        key: 'name',
        filters: [
          {
            text: 'Active',
            value: 'active'
          },
          {
            text: 'Inactive',
            value: 'inactive'
          }
        ],
        fixed: 'left',
        render: (text, record) => (
          <>
            {isAfter(
              parseISO(record.account.createdAt),
              newMemberIndicatorTime
            ) && <Tag color="green">New User!</Tag>}
            {isAfter(parseISO(record.createdAt), newMemberIndicatorTime) && (
              <Tag color="green">New Member!</Tag>
            )}
            <span
              key={record.account.id}
              className="group__link"
              onClick={e => {
                history.push(`/account/${record.account.id}`);
              }}
            >
              {`${record.account.firstName} ${record.account.lastName}`}
            </span>
          </>
        ),
        onFilter: (value, account) => {
          return value === 'active'
            ? account.courses.length > 0
            : account.courses.length === 0;
        }
      },
      {
        title: 'Email',
        key: 'email',
        dataIndex: ['account', 'email']
      },
      {
        title: member.actionsTitle,
        key: 'delete',
        render: (text, record) => (
          <div className="member-actions">
            <Button
              icon={<MailOutlined />}
              size="small"
              title="Send email"
              onClick={() => {
                setShowEmailModal(true);
                setCurrentSelectedEmailUsers([record.account.id]);
              }}
            />
            <Popconfirm
              title="Remove this member from this group?"
              icon={<WarningOutlined style={{ color: 'red' }} />}
              onConfirm={() => removeAccount(record.id, showAddAdmin)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="danger"
                icon={<DeleteOutlined />}
                size="small"
                title="Remove member from group"
              />
            </Popconfirm>
          </div>
        )
      }
    ];

    const memberCourseSubscriptions = getCourseSubscriptions(subscriptions);
    getCourseSubscriptionList(memberCourseSubscriptions, columns);

    return columns;
  };

  const getResourceSelectorModalProps = () => {
    if (showAddMember || showAddAdmin) {
      return {
        title: showAddMember ? 'Members' : 'Admins',
        apiResource: API.account,
        additionalPaginatedListProps: {
          defaultOptions: { include: 'programBindings.program' }
        },
        searchPlaceholder: 'Search by Email Address',
        searchAction: (actions, value) =>
          actions.setFilter('email', value && `:${value}`),
        renderHeader: () => (
          <Row>
            <Col span={2}>Select</Col>
            <Col span={6}>Email</Col>
            <Col span={10}>Name</Col>
            <Col span={1} />
          </Row>
        ),
        renderItem: (index, account, isSelected, onClick) => (
          <Row onClick={onClick}>
            <Col span={2}>
              <Checkbox checked={isSelected} />
            </Col>
            <Col span={6}>{account.email}</Col>
            <Col span={10}>
              {account.firstName} {account.lastName}
            </Col>
            <Col span={1}>
              <AccountBindingWarning
                groupId={group.id}
                groupBindings={account.programBindings}
              />
            </Col>
          </Row>
        ),
        isItemDisabled: account => isMemberAccountUsed(account, showAddMember),
        visible: true,
        okButtonText: `Add Selected ${
          showAddMember ? 'Members' : 'Admins'
        } to Group`,
        onSelect: addAccounts,
        onClose: () => {
          setShowAddMember(false);
          setShowAddAdmin(false);
        }
      };
    }
  };

  const isMemberAccountUsed = (account, isMember) => {
    return (
      !!account.programBindings?.length &&
      isMember &&
      account.programBindings.find(binding => binding.isMember)
    );
  };

  const addAccounts = async accountIds => {
    const existingAccounts = accounts.map(account => account.account.id);

    const existingAccountBindings = accounts.filter(
      account => accountIds.includes(account.account.id) && !account.deletedAt
    );

    const newAccountIds = accountIds.filter(
      id => !existingAccounts.includes(id)
    );

    let accountsWithDeletedAt = accounts.filter(
      account => accountIds.includes(account.account.id) && account.deletedAt
    );

    accountsWithDeletedAt.map(programAccountBinding => {
      const deletedLength = accountsWithDeletedAt.filter(
        binding => programAccountBinding.account.id === binding.account.id
      ).length;

      const programAccountBindingsLength = existingAccounts.filter(
        account => account === programAccountBinding.account.id
      ).length;

      if (deletedLength === programAccountBindingsLength) {
        newAccountIds.push(programAccountBinding.account.id);
      }

      accountsWithDeletedAt = accountsWithDeletedAt.filter(
        binding => binding.account.id !== programAccountBinding.account.id
      );

      return '';
    });

    if (existingAccountBindings) {
      await Promise.all(
        existingAccountBindings.map(binding => {
          return API.programAccountBinding.update({
            id: binding.id,
            attributes: {
              isAdmin: showAddAdmin ? true : binding.isAdmin,
              isMember: showAddMember ? true : binding.isMember
            }
          });
        })
      );
    }

    if (newAccountIds) {
      await Promise.all(
        newAccountIds.map(id =>
          API.programAccountBinding.create({
            attributes: {
              isAdmin: showAddAdmin,
              isMember: showAddMember,
              hasAccepted: true
            },
            relationships: {
              account: {
                data: {
                  id,
                  type: 'account'
                }
              },
              program: {
                data: {
                  id: group.id,
                  type: 'program'
                }
              }
            }
          })
        )
      );

      loadAccounts();
    }
    setShowAddMember(false);
    setShowAddAdmin(false);
  };

  const removeAccount = async (accountBindingId, removeAdmin = false) => {
    const accountBinding = accounts.find(
      account => account.id === accountBindingId
    );

    // first, before we remove user from group, we want to remove all their
    // subscriptions associated with this group.
    const usersSubscriptions = subscriptions?.reduce(
      (acc, subscriptionBinding) => {
        const subscriptionsToRemove =
          subscriptionBinding.subscriptionsList?.filter(
            sub => sub.account && sub.account.id === accountBinding.account.id
          ) || [];
        return [...acc, ...subscriptionsToRemove];
      },
      []
    );

    try {
      await Promise.all(
        usersSubscriptions.map((subscription, index) =>
          updateMemberSubscriptionAssignment({
            memberId: accountBinding.id,
            courseId: subscription.course.id,
            previousBoolean: true,
            accountId: accountBinding.account.id,
            subscriptions: usersSubscriptions.slice(
              index,
              usersSubscriptions.length
            )
          })
        )
      );
    } catch (err) {
      notify.show(
        `Error removing subscriptions from Account ID ${accountBinding.account.id}, before removing user from group`
      );
    }

    if (isAdminTable) {
      if (accountBinding.isMember) {
        // if is admin and member, don't add deletedAt value
        await API.programAccountBinding.update({
          id: accountBindingId,
          attributes: {
            isAdmin: false
          }
        });
      } else {
        // if is admin and not a member, add deletedAt value
        await API.programAccountBinding.update({
          id: accountBindingId,
          attributes: {
            isAdmin: false,
            deletedAt: new Date()
          }
        });
      }
    } else {
      if (accountBinding.isAdmin) {
        // if is admin and member, don't add deletedAt value
        await API.programAccountBinding.update({
          id: accountBindingId,
          attributes: {
            isMember: false
          }
        });
      } else {
        // if is a member and not admin, delete
        await API.programAccountBinding.delete({
          id: accountBindingId
        });
      }
    }
    loadAccounts();
  };

  const onSelectChange = selectedRowKeys => {
    if (selectedRowKeys.length === 0) {
      isAdminTable ? setAdminSelectedRows([]) : setMemberSelectedRows([]);
      setCurrentSelectedEmailUsers([]);
    }
    const selectedRowMembers = [];
    const selectedMemberAccounts = [];
    selectedRowKeys.forEach(selectedRowKey => {
      const accountBinding = accounts.find(
        account => account.id === selectedRowKey
      );

      if (accountBinding) {
        // upon selecting row, we want to add them to the possible email
        // sending list, incase the user is trying to send a mass email
        // but we can update the state outside of this loop
        selectedMemberAccounts.push(accountBinding.account.id);
      }

      const selectedSubscriptions = subscriptions
        .map(groupSubscriptions =>
          groupSubscriptions.subscriptionsList.filter(
            subscription =>
              subscription.account?.id === accountBinding.account.id
          )
        )
        .flat();

      selectedSubscriptions.map(subscription =>
        selectedRowMembers.push(subscription)
      );
      const filteredSelectedRows = courseFilter
        ? selectedRowMembers.filter(row => row.course?.title === courseFilter)
        : selectedRowMembers;

      isAdminTable
        ? setAdminSelectedRows(filteredSelectedRows)
        : setMemberSelectedRows(filteredSelectedRows);
    });

    setCurrentSelectedEmailUsers(selectedMemberAccounts);
  };

  const tableRowSelector = isAdminTable
    ? adminSelectedRows
    : memberSelectedRows;

  const rowSelection = {
    tableRowSelector,
    onChange: onSelectChange
  };

  const getPageSizeOptions = totalRecords =>
    ['20', '50', '100', '500', `${totalRecords}`]
      .map(Number)
      .sort((a, b) => a - b)
      .filter(option => option <= totalRecords) // get rid of records greater than total
      .map(String); // convert back to strings for antd

  const onChange = (pagination, filters, sorter, extra) => {
    setPaginationInfo(pagination);
    extra.action === 'filter' && setSubscriptionDateFilter(filters);
  };

  const accountDataSource = orderBy(
    accounts.filter(account => {
      const validGroupType = account[member.groupMemberType];
      const validForFieldsFilter =
        !memberFilters?.length ||
        memberFilters.some(filter =>
          `${account.account.email?.toLowerCase()}${account.account.firstName?.toLowerCase()}${account.account.lastName?.toLowerCase()}`.includes(
            filter?.toLowerCase()
          )
        );
      const validForCourseFilter =
        !courseFilter ||
        (account.account.subscriptionsList?.length === 0
          ? false
          : !!account.account.subscriptionsList.find(
              currentSubscription =>
                currentSubscription.programCourseBinding?.programId ===
                  group.id && currentSubscription.course.title === courseFilter
            ));

      return validGroupType && validForFieldsFilter && validForCourseFilter;
    }),
    member.orderBy
  );

  const courseFilterOptions = () =>
    Object.values(getCourseSubscriptions(subscriptions));

  const removeFiltersFromHiddenCourses = value => {
    const subscriptionDateFilterCopy = Object.assign(
      {},
      subscriptionDateFilter
    );

    Object.keys(subscriptionDateFilterCopy)
      .filter(key => key !== value)
      .map(filter => (subscriptionDateFilterCopy[filter] = null));

    return subscriptionDateFilterCopy;
  };

  const membersAreSelected = () =>
    isAdminTable ? adminSelectedRows.length > 0 : memberSelectedRows.length > 0;

  const updateGroupSubscriptions = async endDate => {
    const filteredSubscriptions = subscriptions.filter(
      subscription => subscription.course.title === courseFilter
    );

    let programCourseCounts = {};
    filteredSubscriptions.forEach(filteredSubs => {
      const counts = filteredSubs.subscriptions
        .flat()
        .reduce((bindings, subscription) => {
          // only count account enrolled subsciptions
          if (subscription.account) {
            const bindingKeys = Object.keys(bindings);
            if (
              bindingKeys.length === 0 ||
              !bindingKeys.includes(subscription.programCourseBinding.id)
            ) {
              bindings[subscription.programCourseBinding.id] = 1;
            } else {
              bindings[subscription.programCourseBinding.id]++;
            }
          }
          return bindings;
        }, {});
      programCourseCounts = {
        ...programCourseCounts,
        ...counts
      };
    });

    const selectedMemberBindingCounts = member.selectedRows.reduce(
      (accounts, subscription) => {
        const accountsKeys = Object.keys(accounts);
        if (subscription.course.title === courseFilter) {
          if (
            accountsKeys.length === 0 ||
            !accountsKeys.includes(subscription.programCourseBinding.id)
          ) {
            accounts[subscription.programCourseBinding.id] = 1;
          } else {
            accounts[subscription.programCourseBinding.id]++;
          }
        }
        return accounts;
      },
      {}
    );

    member.selectedRows.map(async memberRow => {
      const memberBindingId = memberRow.programCourseBinding.id;

      try {
        member.selectedRows.map(async memberRow => {
          const {
            data: programCourseBinding
          } = await API.programCourseBinding.find({
            id: memberRow.programCourseBinding.id
          });

          const subscriptionFound = await API.subscription.where({
            filter: {
              account: memberRow.account.id,
              programCourseBinding: programCourseBinding.id
            }
          });

          const bindingAttributes = _.clone(programCourseBinding);
          const subscriptionAttributes = _.clone(subscriptionFound.data[0]);
          bindingAttributes.endDate = endDate;
          subscriptionAttributes.endedAt = endDate;

          // check if all enrolled members of course are selected to be updated
          if (
            selectedMemberBindingCounts[memberBindingId] !==
            programCourseCounts[memberBindingId]
          ) {
            subscriptionAttributes.account = null;

            const relationships = {
              course: { data: { id: memberRow.course.id, type: 'course' } },
              program: { data: { id: group.id, type: 'program' } }
            };

            // instead of updating pcb and sub, create new ones
            const {
              data: programCourseBinding
            } = await API.programCourseBinding.create({
              attributes: {
                notes: 'created through admin group renewal',
                startDate: bindingAttributes.startDate,
                endDate: bindingAttributes.endDate
              },
              relationships
            });

            await createSubscription(
              memberRow.course.id,
              subscriptionAttributes.startedAt,
              endDate,
              memberRow.account.id,
              programCourseBinding.id
            );

            // remove seat from other enrollment
            await API.subscription.update({
              id: subscriptionFound.data[0].id,
              attributes: subscriptionAttributes
            });
          } else {
            await API.programCourseBinding.update({
              id: programCourseBinding.id,
              attributes: bindingAttributes
            });

            await API.subscription.update({
              id: subscriptionFound.data[0].id,
              attributes: subscriptionAttributes
            });
          }

          loadSubscriptions();
        });
      } catch (error) {
        throw error;
      }
    });
  };

  const downloadTable = useCallback(() => {
    let csv =
      'firstName,lastName,email,institution,phone,residencyPhone,residencyDirector,residencyProgramName,residencyWebsite,graduatedAt,isCurrentlyStudying,exam,nextExamDate';
    // add all shortnames of courses available to the group
    const groupCourses = uniqBy(
      group.programCourseBindingsList.map(binding => binding.course),
      course => course.shortname
    );

    for (const { shortname } of groupCourses) {
      csv += `,${shortname},${shortname} - end date`;
    }
    csv += '\n';

    /**
     * @type {{
     *  [userId: string]: {
     *    firstName: string;
     *    lastName: string;
     *    email: string;
     *    institution: string;
     *    phone: string;
     *    residencyPhone: string;
     *    residencyDirector: string;
     *    residencyProgramName: string;
     *    residencyWebsite: string;
     *    graduatedAt: string;
     *    isCurrentlyStudying: string;
     *    exam: string;
     *    nextExamDate: string;
     *    subscriptions: {
     *      course: {
     *        shortname,
     *      };
     *      endedAt: string;
     *    }[];
     *  }
     * }}
     */
    const userRows = {};

    for (const {
      account: {
        id: accountId,
        firstName,
        lastName,
        email,
        institution,
        phone,
        residencyPhone,
        residencyDirector,
        residencyProgramName,
        residencyWebsite,
        graduatedAt,
        isCurrentlyStudying,
        exam,
        nextExamDate
      },
      course,
      startedAt,
      endedAt
    } of allUserSubscriptions) {
      if (userRows[accountId]) {
        userRows[accountId].subscriptions[course.id] = {
          startedAt,
          endedAt
        };
      } else {
        userRows[accountId] = {
          firstName,
          lastName,
          email,
          institution,
          phone,
          residencyPhone,
          residencyDirector,
          residencyProgramName,
          residencyWebsite,
          graduatedAt,
          isCurrentlyStudying,
          exam,
          nextExamDate,
          subscriptions: {
            [course.id]: {
              startedAt,
              endedAt
            }
          }
        };
      }
    }

    for (const userRow of Object.values(userRows)) {
      csv += `${userRow.firstName ?? ''},${userRow.lastName ??
        ''},${userRow.email ?? ''},${userRow.institution ??
        ''},${userRow.phone ?? ''},${userRow.residencyPhone ??
        ''},${userRow.residencyDirector ?? ''},${userRow.residencyProgramName ??
        ''},${userRow.residencyWebsite ?? ''},${
        userRow.graduatedAt
          ? format(parseISO(userRow.graduatedAt), 'yyyy-MM-dd')
          : ''
      },${userRow.isCurrentlyStudying},${userRow.exam ??
        ''},${userRow.nextExamDate ?? ''}`;
      for (const { id: courseId } of groupCourses) {
        if (userRow.subscriptions[courseId]) {
          csv += `,x,${
            userRow.subscriptions[courseId].endedAt
              ? format(
                  parseISO(userRow.subscriptions[courseId].endedAt),
                  'yyyy-MM-dd'
                )
              : ''
          }`;
        } else {
          csv += ',,';
        }
      }
      csv += '\n';
    }

    const fileURL = encodeURI(csv);
    const temporaryLink = document.createElement('a');
    temporaryLink.download = `${group.shortname}_subscriptions.csv`;
    temporaryLink.href = `data:text/csv;charset=utf-8,${fileURL}`;
    document.body.appendChild(temporaryLink);
    temporaryLink.click();
    document.body.removeChild(temporaryLink);
  }, [allUserSubscriptions, group.programCourseBindingsList, group.shortname]);

  return (
    <div className="group__form-item">
      <Label className="group__section-title">{memberType}s</Label>
      {accounts && subscriptions ? (
        <>
          {courseFilterOptions().length > 1 && (
            <Select
              placeholder="Filter Course"
              onSelect={value => {
                const subscriptionDateFilterForCourse = removeFiltersFromHiddenCourses(
                  value
                );
                setCourseFilter(value);
                setSubscriptionDateFilter(subscriptionDateFilterForCourse);
              }}
              allowClear={true}
              onClear={() => setCourseFilter(null)}
              style={{ margin: '0 0 16px 16px', width: '250px' }}
            >
              {courseFilterOptions().map(course => {
                return (
                  <Select.Option key={course.id} value={course.title}>
                    {course.title}
                  </Select.Option>
                );
              })}
            </Select>
          )}
          <Form
            form={memberFilterForm}
            initialValues={{ memberFilters }}
            onFinish={values =>
              setMemberFilters(
                values.memberFilters
                  ?.split(',')
                  ?.map(filter => filter?.trim().replace(/ /g, ''))
                  ?.filter(Boolean)
              )
            }
            style={{ display: 'inline-flex' }}
          >
            <Form.Item name="memberFilters" style={{ width: '250px' }}>
              <Input placeholder="Filter by name or email" />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                Search
              </Button>
            </Form.Item>
            {memberFilters?.length > 0 && (
              <Form.Item>
                <Button
                  type="secondary"
                  onClick={() => {
                    memberFilterForm.setFieldValue('memberFilters', '');
                    setMemberFilters([]);
                  }}
                >
                  Clear
                </Button>
              </Form.Item>
            )}
          </Form>
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: 16, height: 32 }}
            onClick={() => setShowDatePicker(true)}
            disabled={
              !membersAreSelected() ||
              (!courseFilter && courseFilterOptions().length > 1)
            }
          >
            Renew Selected
          </Button>
          <Button
            type="primary"
            icon={<MailOutlined />}
            size="small"
            style={{ marginLeft: 16, height: 32 }}
            onClick={() => setShowEmailModal(true)}
            disabled={
              currentSelectedEmailUsers && currentSelectedEmailUsers.length < 1
            }
          >
            E-mail Selected Members
          </Button>
          {showDatePicker && membersAreSelected() && (
            <DatePicker
              style={{ marginLeft: 16 }}
              onChange={value => value && updateGroupSubscriptions(value)}
            />
          )}
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            size="small"
            style={{ marginLeft: 16, height: 32 }}
            disabled={!accounts.length}
            onClick={() => downloadTable()}
          >
            Download Table
          </Button>
          <Table
            className="group__table"
            dataSource={accountDataSource}
            onChange={onChange}
            rowKey="id"
            pagination={{
              current: paginationInfo.current,
              pageSize: paginationInfo.pageSize,
              total: accountDataSource.length,
              showSizeChanger: true,
              pageSizeOptions: getPageSizeOptions(accountDataSource.length),
              onShowSizeChange: (current, size) => {
                setPaginationInfo({
                  current,
                  pageSize: size
                });
              }
            }}
            columns={getMemberTableColumns(subscriptions)}
            rowClassName={account =>
              !isAdminTable &&
              !account?.subscriptionsList?.length > 0 &&
              'disabled-row'
            }
            rowSelection={rowSelection}
            loading={loadingAccounts}
          />
        </>
      ) : (
        <Card className="group__table">{`No ${memberType}s Assigned`}</Card>
      )}
      <hr />
      <Space>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="small"
          onClick={() => member.addMember(true)}
        >
          {`Add Existing ${memberType}`}
        </Button>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          size="small"
          onClick={() => member.createMember(true)}
        >
          {`Create a New ${memberType}`}
        </Button>
        {!isAdminTable && <UploadCSVModal group={group} />}
      </Space>
      {(showAddMember || showAddAdmin) && (
        <ResourceSelectorModal {...getResourceSelectorModalProps()} />
      )}

      {(showCreateMember || showCreateAdmin) && (
        <UserAddModal
          group={group}
          isAdmin={isAdminTable}
          modalVisible={showCreateMember || showCreateAdmin}
          setModalVisible={visible => {
            if (!visible) {
              loadAccounts();
            }

            setShowCreateMember(visible);
            setShowCreateAdmin(visible);
          }}
        />
      )}

      <div className="group__save-notice">
        All changes are automatically saved
      </div>
      <CustomEmailModal
        accountIds={currentSelectedEmailUsers}
        groupName={group?.name}
        showModal={showEmailModal}
        setShowModal={state => setShowEmailModal(state)}
      />
    </div>
  );
}
