import { gql } from '@apollo/client';

export const GET_GROUP = gql`
  query GetGroup($id: UUID!) {
    program(id: $id) {
      id
      name
      shortname
      createdAt
      updatedAt
      manager {
        email
        firstName
        isAdmin
        lastName
        id
      }
      programCourseBindingsList {
        id
        startDate
        endDate
        notes
        course {
          id
          title
          shortname
        }
        accountManager {
          id
          firstName
          lastName
          email
        }
      }
      programAccountBindingsList(filter: { deletedAt: { isNull: true } }) {
        id
        isAdmin
        isMember
        account {
          id
          isActive
          email
          firstName
          lastName
          deletedAt
          subscriptionsList(
            filter: { programCourseBindingId: { isNull: false } }
          ) {
            id
            startedAt
            endedAt
            isActive
            account {
              id
            }
            programCourseBinding {
              id
              programId
            }
            course {
              id
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNT_GROUP_SUBSCRIPTIONS = gql`
  query GetAccountGroupSubscriptions($programCourseBindingIds: [UUID!]) {
    subscriptionsList(
      filter: {
        programCourseBindingId: { isNull: false, in: $programCourseBindingIds }
      }
    ) {
      programCourseBindingId
      id
      startedAt
      endedAt
      deletedAt
      course {
        id
        title
        shortname
      }
      account {
        id
        email
        firstName
        lastName
        institution
        phone
        residencyPhone
        residencyDirector
        residencyProgramName
        residencyWebsite
        graduatedAt
        isCurrentlyStudying
        exam
        nextExamDate
        deletedAt
      }
    }
  }
`;

export const DELETE_GROUP_MUTATION = gql`
  mutation DeleteGroup($id: UUID!) {
    deleteProgram(input: { id: $id }) {
      clientMutationId
      deletedProgramNodeId
    }
  }
`;

export const CREATE_GROUP_MUTATION = gql`
  mutation CreateProgram(
    $id: UUID!
    $name: String!
    $managerId: UUID
    $shortname: String!
    $createdAt: Datetime!
    $updatedAt: Datetime!
  ) {
    createProgram(
      input: {
        program: {
          id: $id
          name: $name
          managerId: $managerId
          shortname: $shortname
          updatedAt: $updatedAt
          createdAt: $createdAt
        }
      }
    ) {
      clientMutationId
      program {
        id
        name
      }
    }
  }
`;
