import React, { useState } from 'react';
import { Tabs, Radio, Spin } from 'antd';
import { useQuery, gql } from '@apollo/client';
import SingleImageInput from '../Upload/SingleImageInput';

import RichTextEditor from '../RichTextEditor';

import './QuestionOption.css';

export const GET_ANSWER_EXPLANATION_IMAGES = gql`
  query getAnswerExplanationImagesList($imageIds: [UUID!]) {
    imagesList(filter: { id: { in: $imageIds } }) {
      id
      url
    }
  }
`;

export default function QuestionOption({ option, index, updateOption }) {
  const [onAnswer, setOnAnswer] = useState(true);
  const { data, loading } = useQuery(GET_ANSWER_EXPLANATION_IMAGES, {
    skip: option.explanationImages?.length === 0,
    variables: { imageIds: option.explanationImages }
  });

  const items = [
    {
      key: '1',
      label: 'Answer',
      children: (
        <RichTextEditor
          className="question-option__option-body"
          defaultValue={option.body}
          onChange={value => updateOption({ ...option, body: value })}
        />
      )
    },
    {
      key: '2',
      label: 'Explanation',
      children: (
        <RichTextEditor
          className="question-option__option-explanation"
          defaultValue={option.explanation}
          onChange={value => updateOption({ ...option, explanation: value })}
        />
      )
    },
    ...(data && data.imagesList
      ? [
          {
            key: '3',
            label: 'Image',
            children: data.imagesList.map(imageListItem => (
              <SingleImageInput
                key={imageListItem.id}
                name={imageListItem.id}
                value={imageListItem}
                path="assessments/images"
              />
            ))
          }
        ]
      : [])
  ];

  return loading ? (
    <div className="">
      <Spin />
    </div>
  ) : (
    <div className={`question-option question-option-${index}`}>
      <div className="question-option__radio-button">
        <Radio
          value={index}
          onChange={value => {
            const checked = value.target.checked;
            updateOption({ ...option, correctOption: checked });
          }}
        >
          Correct Option
        </Radio>
      </div>
      <Tabs
        defaultActiveKey="1"
        onChange={() => setOnAnswer(!onAnswer)}
        items={items}
      />
    </div>
  );
}
